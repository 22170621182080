<template>
    <ButtonWrapper
        :is-draggable="false"
        :is-deletable="false"
        disable-button-menu
        class="message-response m-b-1"
    >
        <template #responses>
            <Select
                placeholder="Select a response"
                label="Response"
                :value="data.id"
                :options="options"
                :has-margin-bottom="false"
                slot-enabled
                @input="$emit('input', $event)"
            >
                <template v-slot:select="slotProps">
                    <span :class="{ 'highlight-option': slotProps.option.id === 9999 }">
                        {{ slotProps.option.value }}
                    </span>
                </template>
            </Select>
        </template>
        <template #responseButtons>
            <IconButton
                v-if="showAddButton"
                class="m-b-2 message-response-action"
                icon="plus-2"
                @click="$emit('addButtonClick', data)"
            />
            <IconButton
                v-if="showRemoveButton"
                class="message-response-remove-button m-b-2 message-response-action"
                icon="cross-simple"
                @click="$emit('removeButtonClick')"
            />
        </template>
    </ButtonWrapper>
</template>

<script>
import ButtonWrapper from '@/components/ui/ButtonWrapper';
import Select from '@/components/forms/Select';
import IconButton from '@/components/ui/IconButton';

export default {
    name: 'MessageResponse',
    components: {
        ButtonWrapper,
        Select,
        IconButton,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        options: {
            type: Array,
            default: () => [],
        },
        showAddButton: {
            type: Boolean,
            required: true,
        },
        showRemoveButton: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.message-response-remove-button /deep/ .icon {
    font-size: 12px;
}
.highlight-option {
    color: $primary-text-button-text-color;
}
.button-wrapper__element {
    padding-top: 0;
}
.message-response /deep/ .button-wrapper__center {
    margin-right: 0px;
}
.message-response-action {
    margin-left: 5px;
}
</style>
