<template>
    <div>
        <FormInfoBox button-text="Edit Search" :message="messageInfo" @click="handleOpenLogicJump">
            <Input
                :id="`${rule.id}_${rule.name}`"
                type="text"
                :name="rule.attribute"
                label="Search Message"
                placeholder="Search for a specific message"
                default-icon="search"
                :default-icon-size="16"
                :value="inputValue"
                :has-margin-bottom="false"
                autocomplete="off"
                @focus="handleOpenLogicJump"
            />
        </FormInfoBox>

        <div v-if="messageResponses.length" class="message-responses">
            <MessageResponse
                v-for="(obj, index) in selectedMessageResponses"
                :key="`response_${index}`"
                :options="messageResponses"
                :show-add-button="showAddResponseButton(index)"
                :show-remove-button="selectedMessageResponses.length > 0"
                :data="obj"
                :class="index < selectedMessageResponses.length - 1 ? 'm-b-1' : ''"
                @input="(id) => handleResponseInput(messageResponses[id], index)"
                @addButtonClick="handleNewMessageResponse"
                @removeButtonClick="handleRemoveMessageResponse(index)"
            />
        </div>
    </div>
</template>

<script>
import MessageResponse from '@/components/MessageResponse';
import { Input, FormInfoBox } from '@/components/forms';
import { ContentType } from '@/store/enums';

export default {
    name: 'AttributeMessage',
    components: {
        MessageResponse,
        FormInfoBox,
        Input,
    },
    props: {
        rule: {
            type: Object,
            required: true,
        },
    },
    computed: {
        inputValue() {
            if (!this.rule.content) {
                return '';
            }
            return this.rule.content.contentItemName;
        },
        messageResponses() {
            if (!this.rule.content || !this.rule.content.messageResponses) {
                return [];
            }
            return this.rule.content.messageResponses.map((value, id) => ({ id, value }));
        },
        selectedMessageResponses() {
            const emptyRes = { id: -1 };
            if (!this.rule.responseItems || !this.rule.content) {
                return [emptyRes];
            }
            return this.rule.responseItems.map((response) => {
                const res = this.messageResponses.find((obj) => obj.value === response.text);
                return res ? res : emptyRes;
            });
        },
        messageInfo() {
            if (!this.rule.content) {
                return '';
            }
            return `${this.rule.content.channelName} • ${this.rule.content.convoName}`;
        },
    },
    methods: {
        showAddResponseButton(index) {
            return (
                index === this.selectedMessageResponses.length - 1 &&
                index !== this.messageResponses.length - 1
            );
        },
        updateResponses(response, index = null) {
            const data = [...(this.rule.responseItems || [])];
            const idx = index !== null ? index : data.length;
            data[idx] = response;
            this.$emit('updateResponses', data);
        },
        handleRemoveMessageResponse(index) {
            const data = [...(this.rule.responseItems || [])];
            data.splice(index, 1);
            this.$emit('updateResponses', data);
        },
        handleNewMessageResponse() {
            this.updateResponses({});
        },
        handleResponseInput(payload, index) {
            this.updateResponses({ id: payload.id, text: payload.value }, index);
        },
        handleOpenLogicJump() {
            this.$emit('openLogicJump', {
                id: this.rule.id,
                value: this.rule.value,
                type: ContentType.Message,
            });
        },
    },
};
</script>
